<template>
    <section class="position-relative">
        <cargando v-if="loading" />
        <tabla-general :data="clientes" :columnas="dataColumnas" :usar-paginacion="false" :activar-seleccion-multiple="multiple" alto="calc(100vh - 242px)" @seleccion="datosSeleccionados">
            <template slot="cabecera-izquierda">
                <div class="col-auto text-general text-center f-16 f-600">
                    {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }}  del cupon
                </div>
            </template>
            <template slot="cabecera-derecha">
                <el-tooltip v-if="multiple" placement="bottom" :content="`Quitar a los ${$config.cliente} seleccionados`" effect="light">
                    <div class="d-middle-center bg-general2 br-10 p-1 text-white mx-1 f-18 block-button" style="width:35px;height:35px;" @click="modalEliminarClientesMasivo">
                        <i class="icon-delete-outline f-22 cr-pointer" />
                    </div>
                </el-tooltip>
                <el-tooltip v-if="multiple" placement="bottom" :content="`Cambiar el limite de uso de los ${$config.cliente} seleccionados`" effect="light">
                    <div class="d-middle-center bg-general2 br-10 p-1 text-white mx-1 f-18 block-button" style="width:35px;height:35px;" @click="modalCambiarLimite">
                        <i class="icon-cupon f-15 cr-pointer" />
                    </div>
                </el-tooltip>
                <el-tooltip v-if="multiple" placement="bottom" :content="`Cambiar Vigencia para los ${$config.cliente} seleccionados`" effect="light">
                    <div class="d-middle-center bg-general2 br-10 p-1 text-white mx-1 f-18 cr-pointer" style="width:35px;height:35px;" @click="modalCambiarVigencia">
                        <i class="icon-calendar-sync-outline f-22 cr-pointer" />
                    </div>
                </el-tooltip>
                <el-tooltip v-if="multiple || datosBasicos.aplica_usuarios === 2" placement="bottom" :content="`Buscar ${$config.cliente + 's'} por cliterios`" effect="light">
                    <div class="d-middle-center bg-general2 br-10 p-1 text-white mx-1 f-18 cr-pointer" style="width:35px;height:35px;" @click="$router.push({name: 'admin.cupones.editar.buscar-clientes',params:{id_cupon:id_cupon}})">
                        <i class="icon-account-search f-22 cr-pointer" />
                    </div>
                </el-tooltip>
            </template>
            <template slot="adicionales-derecha">
                <el-table-column v-if="datosBasicos.destinatario === 1" :label="`${$config.vendedor} principal`" class-name="text-center text-general f-16" width="220">
                    <template slot-scope="{row}">
                        {{ row.lechero }}
                    </template>
                </el-table-column>
                <el-table-column label="Cupón aplicado" width="180" class-name="text-general f-16 text-center">
                    <template slot-scope="{row}">
                        <div class="row mx-0 justify-center">
                            <div class="col-auto">
                                <span class="text-general f-18">
                                    {{ row.padre.numerador }} / {{ row.padre.denominador }}
                                    <el-tooltip v-if="multiple" placement="bottom" :content="`Buscar ${$config.cliente} por cliterios`" effect="light">
                                        <div slot="content">
                                            <p class="mb-2">
                                                Vigencia:{{ row.padre.fecha_vigencia | helper-fecha('DD MMM YYYY') }}
                                            </p>
                                            Aplicado: <p v-for="(f, idx) in row.padre.fechas_usos" :key="idx">
                                                {{ f | helper-fecha('DD MMM YYYY') }}
                                            </p>
                                        </div>
                                        <i v-if="row.padre.icono == 2" class="icon-cupon f-18 text-general2" />
                                        <i v-if="row.padre.icono == 3" class="icon-cupon f-18 text-general" />
                                        <i v-if="row.padre.icono == 1" class="icon-cupon f-18 text-general-red" />
                                    </el-tooltip>
                                </span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Fecha de uso" width="180" class-name="text-general f-16 text-center">
                    <template slot-scope="{row}">
                        <div class="row mx-0 justify-center">
                            <span class="text-general f-18">
                                {{ row.padre.ultimo_uso | helper-fecha("DD-MMM-YYYY") }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="datosBasicos.tipo == 2" label="Bono aplicado" width="180" class-name="text-general f-16 text-center">
                    <template slot-scope="{row}">
                        <div v-if="row.hijo.denominador != null" class="row mx-0 justify-center">
                            <div class="col-auto">
                                <span class="text-general f-18">
                                    {{ row.hijo.numerador }} / {{ row.hijo.denominador }}
                                    <el-tooltip v-if="multiple" placement="bottom" content="Buscar clientes por cliterios" effect="light">
                                        <div slot="content">
                                            <p class="mb-2">
                                                Vigencia:{{ row.hijo.fecha_vigencia | helper-fecha('DD MMM YYYY') }}
                                            </p>
                                            Aplicado: <p v-for="(f, idx) in row.hijo.fechas_usos" :key="idx">
                                                {{ f | helper-fecha('DD MMM YYYY') }}
                                            </p>
                                        </div>
                                        <i v-if="row.hijo.icono == 2" class="icon-cupon f-18 text-general2" />
                                        <i v-if="row.hijo.icono == 3" class="icon-cupon f-18 text-general" />
                                        <i v-if="row.hijo.icono == 1" class="icon-cupon f-18 text-general-red" />
                                    </el-tooltip>
                                </span>
                            </div>
                        </div>
                        <div v-else class="row mx-0 justify-center">
                            --
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="datosBasicos.tipo == 2" prop="fecha_uso" label="Fecha de uso" width="180" class-name="text-general f-16 text-center">
                    <template slot-scope="{row}">
                        <div class="row mx-0 justify-center">
                            <span class="text-general f-18">
                                {{ row.hijo.ultimo_uso | helper-fecha("DD-MMM-YYYY") }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="80" class-name="f-16 text-center">
                    <template slot-scope="{row}">
                        <el-tooltip v-show="multiple" placement="bottom" content="Eliminar" effect="light">
                            <i class="icon-trash-empty f-25 text-general-red cr-pointer" @click="modalEliminarCliente(row)" />
                        </el-tooltip>
                    </template>
                </el-table-column>
            </template>
        </tabla-general>
        <!-- Partials -->
        <modal ref="modalCambiarLimite" titulo="Cambiar limite de uso" no-aceptar adicional="Guardar" @adicional="cambiarLimiteUso">
            <div class="row mx-0 justify-center">
                <div class="col-8">
                    <p class="text-center text-general f-14">
                        Límite de uso por {{ $config.cliente }}
                    </p>
                    <el-input-number v-model="datosBasicos.limite_usuario" class="w-100" controls-position="right" :min="1" />
                </div>
            </div>
        </modal>
        <modal-cambiar-vigencia ref="modalCambiarVigencia" @update="listarClientes" />
        <modal-eliminar ref="modalEliminarClienteEspecifico" :titulo="`Eliminar ${$config.cliente}`" :mensaje="`Desea eliminar el uso del cupon para este ${$config.cliente}`" @eliminar="eliminarCliente" />
        <modal-eliminar ref="modalEliminarClientesMasivo" :titulo="`Quitar ${$config.cliente} de la lista`" :mensaje="`¿Desea eliminar el uso del cupón para los ${multipleSelection.length} ${$config.cliente} seleccionados?`" @eliminar="eliminarClienteMasivo" />
    </section>
</template>

<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    components: {
        modalCambiarVigencia: () => import('../partials/modalCambiarVigencia')
    },
    data(){
        return {
            tableData: [
                {
                    nombre: 'Juan Carlos Villegas Ardila',
                    cedis: 'Bucaramanga',
                    leechero_principal: 'Daniel Castellanos',
                    fecha_uso: '12 Feb. 2021'
                }
            ],
            dataColumnas: [
                { valor: 'nombre', titulo:"Nombre", class: 'text-general f-16', ancho: 250 },
                { valor: 'cedis', titulo:"Cedis", class: 'text-general f-16' },
                //{ valor: 'lechero', titulo:"Leechero principal", class: 'text-general f-16', ancho: 200 },
            ],
            limite: '',
            checked: false,

            multiple:false,
            clientes:[],

            multipleSelection:[],
            id_eliminar:null,
            loading:false
        }
    },
    computed:{
        ...mapGetters({
            datosBasicos: 'cupones/cupones/datosBasicos',
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        }
    },
    created(){
        this.getDatosBasicos()
    },
    mounted(){
        this.listarClientes()
    },
    methods: {
        async getDatosBasicos(){
            try {
                await this.$store.dispatch('cupones/cupones/getDatosBasicos',this.id_cupon)
                if(this.datosBasicos.aplica_usuarios === 2){
                    this.multiple = true
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        async listarClientes(){
            try {
                this.loading = true
                const {data} = await Cupones.listarClientes(this.id_cupon)
                this.clientes = data.clientes
            } catch (e){
                this.error_catch(e)
            }finally{
                this.loading =false
            }
        },
        datosSeleccionados(items){
            //console.log(items);
            this.multipleSelection  = []
            _.forEach(items,(s)=>{
                this.multipleSelection.push(s.id_user)
            })

        },
        modalEliminarCliente(row){
            this.id_eliminar = row.id_user
            this.$refs.modalEliminarClienteEspecifico.toggle()
        },
        async eliminarCliente(){
            try {

                let form = {
                    id_cupon:this.id_cupon,
                    id_user:this.id_eliminar
                }
                const {data} = await  Cupones.eliminarCliente(form)
                this.notificacion('','Se actualizo el limite de cupones permitidos para el cliente','success')
                this.$refs.modalEliminarClienteEspecifico.toggle()
                this.listarClientes()

            } catch (e){
                this.error_catch(e)
            }
        },
        modalEliminarClientesMasivo(){
            if(this.multipleSelection.length === 0){
                this.notificacion('Alerta','Por favor seleccione almenos 1 cliente','info')
                return
            }
            this.$refs.modalEliminarClientesMasivo.toggle();
        },
        async eliminarClienteMasivo(){
            try {
                if(this.multipleSelection.length === 0){
                    this.notificacion('Alerta','Por favor seleccione almenos 1 cliente','info')
                    return
                }
                let form = {
                    id_cupon:this.id_cupon,
                    id_users:this.multipleSelection
                }
                const {data} = await Cupones.eliminarClienteMasivo(form)
                this.notificacion('','Se actualizo el limite de cupones permitidos para los clientes seleccionados','success')
                this.$refs.modalEliminarClientesMasivo.toggle();
                this.listarClientes()

            } catch (e){
                this.error_catch(e)
            }
        },
        modalCambiarLimite(){
            if(this.multipleSelection.length === 0){
                this.notificacion('Alerta','Por favor seleccione almenos 1 cliente','info')
                return
            }
            this.$refs.modalCambiarLimite.toggle();
        },
        async cambiarLimiteUso(){
            try {
                let form = {
                    id_cupon:this.id_cupon,
                    id_users:this.multipleSelection,
                    cantidad_permitida: this.datosBasicos.limite_usuario
                }
                const {data} = await Cupones.cambiarLimiteUsoMasivo(form)
                this.notificacion('','Se actualizo el limite de cupones permitidos para los clientes seleccionados','success')
                this.$refs.modalCambiarLimite.toggle();
                this.listarClientes()

            } catch (e){
                this.error_catch(e)
            }
        },
        modalCambiarVigencia(){
            if(this.multipleSelection.length === 0){
                this.notificacion('Alerta','Por favor seleccione almenos 1 cliente','info')
                return
            }
            this.$refs.modalCambiarVigencia.toggle(this.multipleSelection);
        },
        eliminarMotivo(){
            this.$refs.modalEliminarMotivo.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.icon-cupon::before{
    display: initial !important;
}
.block-button{
    opacity: 0.5;
    pointer-events: auto;
}
</style>
